import client from "@/api/client";

const api = {
  // 库存管理-获取目标仓库
  getInvWarehouseList: "/api/WarehouseService/TransitUserOutBound/GetInvWarehouseList",
  // 库存管理-获取货件详情分页列表
  getTransitsLabelChangeTaskListPaged: "/api/WarehouseService/TransitsLabelChangeTask/GetListPaged",
  // 库存管理-查询货件已入库货件列表
  getLabelShipmentPagedList: "/api/WarehouseService/TransitUserOutBound/GetLabelShipmentPagedList",
  // 库存管理-查询货件详细列表
  getCreateLabelShipmentDetailPagedList: "/api/WarehouseService/TransitUserOutBound/GetCreateLabelShipmentDetailPagedList",
  // 库存管理-创建标签任务(第一步)
  createTransitsLabelChangeTask: "/api/WarehouseService/TransitsLabelChangeTask/Create",
  // 库存管理-保存标签任务(第二步)
  setBoxProduct: "/api/WarehouseService/TransitsLabelChangeTask/SetBoxProduct",
  // 库存管理-查询标签任务费用(第三步)
  getTransitsLabelChangeTaskFee: "/api/WarehouseService/TransitsLabelChangeTask/GetFee",
  // 库存管理-确认支付(第三步)
  confirmTransitsLabelChangeTask: "/api/WarehouseService/TransitsLabelChangeTask/Confirm",
  // 库存管理-查询换标任务详细
  getTransitsLabelChangeTaskById: "/api/WarehouseService/TransitsLabelChangeTask/GetById",
  // 库存管理-查询换标任务详细-箱列表
  getTransitsLabelChangeTaskDetailList: "/api/WarehouseService/TransitsLabelChangeTaskDetail/GetList",
  // 库存管理-取消
  cancelTransitsLabelChangeTask: "/api/WarehouseService/TransitsLabelChangeTask/Cancel",
}

export const getInvWarehouseList = () => {
  return client.request({
    url: api.getInvWarehouseList,
    method: "POST",
  });
};

export const getTransitsLabelChangeTaskListPaged = (data) => {
  return client.request({
    url: api.getTransitsLabelChangeTaskListPaged,
    data,
    method: "POST",
  });
};

export const createTransitsLabelChangeTask = (data) => {
  return client.request({
    url: api.createTransitsLabelChangeTask,
    data,
    method: "POST",
  });
};

export const getLabelShipmentPagedList = (data) => {
  return client.request({
    url: api.getLabelShipmentPagedList,
    data,
    method: "POST",
  });
};

export const getCreateLabelShipmentDetailPagedList = (data) => {
  return client.request({
    url: api.getCreateLabelShipmentDetailPagedList,
    data,
    method: "POST",
  });
};

export const setBoxProduct = (data) => {
  return client.request({
    url: api.setBoxProduct,
    data,
    method: "POST",
  });
};

export const getTransitsLabelChangeTaskFee = (params) => {
  return client.request({
    url: api.getTransitsLabelChangeTaskFee,
    params,
    method: "GET",
  });
};

export const confirmTransitsLabelChangeTask = (data) => {
  return client.request({
    url: api.confirmTransitsLabelChangeTask,
    data,
    method: "POST",
  });
};

export const getTransitsLabelChangeTaskById = (params) => {
  return client.request({
    url: api.getTransitsLabelChangeTaskById,
    params,
    method: "GET",
  });
};

export const getTransitsLabelChangeTaskDetailList = (data) => {
  return client.request({
    url: api.getTransitsLabelChangeTaskDetailList,
    data,
    method: "POST",
  });
};

export const cancelTransitsLabelChangeTask = (data) => {
  return client.request({
    url: api.cancelTransitsLabelChangeTask,
    data,
    method: "POST",
  });
};