<template>
  <a-modal
    width="800px"
    v-model:visible="visible"
    :title="$t('warehouse.select_shipment')"
    :centered="true"
    :mask-closable="false"
  >
    <a-row type="flex" justify="center">
      <a-col>
        <a-row :gutter="16" type="flex" align="middle">
          <a-col><a-input style="width: 250px;" v-model:value="searchState.name" :placeholder="$t('warehouse.shipment_name')" allow-clear></a-input></a-col>
          <a-col><a-input style="width: 250px;" v-model:value="searchState.seShipmentNo" :placeholder="$t('warehouse.se_shipment_no')" allow-clear></a-input></a-col>
          <a-col><a-button type="primary" :loading="tableData.loading" @click="handleSearch">{{ $t('common.search') }}</a-button></a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-table
      class="mt-3"
      size="small"
      :columns="columns"
      :data-source="tableData.tableList"
      :pagination="false"
      :scroll="{ x: 500 }"
      :row-key="(record) => record.id"
      :loading="tableData.loading"
    >
      <template #selfBoxNo="{ record }">
        {{ record.selfBoxNo || '-' }}
      </template>
      <template #operation="{ record }">
        <a-button type="primary" @click="handleConfirm(record)">{{ $t('common.select') }}</a-button>
      </template>
    </a-table>
    <CPager class="text-center mt-3" :page-data="tableData.pageData" @handlePage="handlePage"></CPager>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue';
import { Button, Col, Form, Input, Modal, Row, Table } from 'ant-design-vue';
import CPager from '@/views/components/CPager.vue';
import { useI18n } from "vue-i18n/index";
import { getLabelShipmentPagedList } from '@/api/modules/transit/labelChange.js';

export default defineComponent({
  emits: ['confirm'],
  components: {
    AModal: Modal,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AButton: Button,
    AForm: Form,
    ATable: Table,
    CPager,
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      visible: false,
      searchState: {
        warehouseId: null,
        name: null,
        seShipmentNo: null,
      },
      searchStateCache: {},
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const columns = [
      {
        dataIndex: 'seShipmentNo',
        title: () => vueI18n.t('warehouse.se_shipment_no'),
        width: 150,
      },
      {
        dataIndex: 'name',
        title: () => vueI18n.t('warehouse.shipment_name'),
        width: 300,
      },
      {
        width: 120,
        title: () => vueI18n.t('common.operation'),
        slots: {
          customRender: 'operation',
        }
      },
    ];

    const getPageList = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchStateCache
      );
      getLabelShipmentPagedList(data)
        .then((res) => {
          state.tableData.loading = false;
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .catch(() => {
          state.tableData.loading = false;
        });
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    };

    const handleInitPage = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageList();
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      handleInitPage();
    };

    const handleConfirm = (record) => {
      emit('confirm', record);
      close();
    }

    const open = (warehouseId) => {
      state.visible = true;
      state.searchState.warehouseId = warehouseId;
      state.tableData.tableList = [];
      handleSearch();
    }

    const close = () => {
      state.visible = false;
    }

    return {
      ...toRefs(state),
      columns,
      handlePage,
      handleSearch,
      handleConfirm,
      open,
      close,
    }
  }
})
</script>

<style scoped>

</style>