<template>
  <a-row :gutter="[8, 8]">
    <a-col :span="24">
      <a-row :gutter="8" type="flex" align="middle">
        <a-col>{{ $t('warehouse.belonging_to_warehouse') }}:</a-col>
        <a-col v-if="warehouse">{{ warehouse.warehouseNo }}</a-col>
        <a-col v-else>-</a-col>
      </a-row>
    </a-col>
    <a-col :span="24">
      <a-row :gutter="8" type="flex" align="middle">
        <a-col>{{ $t('warehouse.label_task_name') }}:</a-col>
        <template v-if="isEditName">
          <a-col>
            <a-input ref="newTaskNameInputRef" v-model:value="newTaskName" :maxlength="128" style="width: 250px;" @blur="handleConfirmTaskName"></a-input>
          </a-col>
          <a-col>
            <a-button type="primary" @click="handleConfirmTaskName">{{ $t('common.confirm') }}</a-button>
          </a-col>
        </template>
        <a-col v-else>
          <span>{{ formState.taskName }} <EditOutlined class="text-primary" style="cursor: pointer;" @click="handleShowInput" /></span>
        </a-col>
      </a-row>
    </a-col>
    <a-col :span="24" class="mt-3">
      <a-button type="primary" ghost @click="openShipmentModal">{{ $t('warehouse.select_shipment') }}</a-button>
    </a-col>
    <a-col :span="24">
      <a-row :gutter="32">
        <a-col>
          {{ $t('warehouse.shipment_name') }}: {{ shipmentInfo.name || '-' }}
        </a-col>
        <a-col>
          {{ $t('warehouse.se_shipment_number') }}: {{ shipmentInfo.seShipmentNo || '-' }}
        </a-col>
      </a-row>
    </a-col>
    <a-col :span="24">
      <a-table
        size="small"
        :columns="columns"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500 }"
        :row-key="(record) => record.shipmentDetailId"
      >
        <template #operation>
          <a-button type="primary" :disabled="getCanSelectCount === 0" @click="handleSelectAll">{{ getSelectedCount !== getCanSelectCount ? $t('warehouse.all_selection') : $t('warehouse.cancel_all_of_them') }}</a-button>
        </template>
        <template #operationCustomer="{ record }">
          <template v-if="record.isOperability">
            <a-button v-if="record.selected" type="primary" @click="record.selected = false">{{ $t('common.selected') }}</a-button>
            <a-button v-else type="primary" ghost @click="record.selected = true">{{ $t('common.select') }}</a-button>
          </template>
          <a-button v-else disabled>{{ $t('warehouse.temporarily_unavailable') }}</a-button>
        </template>
        <template #footer>
          {{ $t('warehouse.p0_boxes_have_been_selected_the_total_shipment_is_p1_boxes', [getSelectedCount, tableData.tableList.length]) }}
        </template>
      </a-table>
      <CPager class="text-center mt-3" :page-data="tableData.pageData" @handlePage="handlePage"></CPager>
    </a-col>
    <a-col :span="24">
      <a-row type="flex" justify="end" class="mt-5 mb-3">
        <a-col class="mr-3">
          <a-button type="primary" :loading="confirmLoading" @click="handleConfirm">{{ $t('warehouse.create_and_next') }}</a-button>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
  <ShipmentListModal ref="shipmentListModalRef" @confirm="handleSetShipment" />
</template>

<script>
import { computed, defineComponent, reactive, toRefs, ref, nextTick, watch } from 'vue';
import { Button, Col, Input, Row, Table, message } from 'ant-design-vue';
import CPager from '@/views/components/CPager.vue';
import ShipmentListModal from './ShipmentListModal.vue';
import { useI18n } from "vue-i18n/index";
import { useRouter } from 'vue-router';
import { setFormStateValue } from '@/utils/general.js';
import { getCreateLabelShipmentDetailPagedList, createTransitsLabelChangeTask } from '@/api/modules/transit/labelChange.js';
import { useTab } from "@/hooks/tabs/index";
import { refInputElemFocus } from '@/utils/domoperate';

export default defineComponent({
  name: 'stepOne',
  emits: ['next'],
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AButton: Button,
    ATable: Table,
    CPager,
    ShipmentListModal,
  },
  props: {
    pageState: {
      type: Object,
      default: () => ({})
    },
    warehouse: {
      type: Object,
      default: () => ({})
    }
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const { delVisitedRoute } = useTab();

    const shipmentListModalRef = ref(null);
    const newTaskNameInputRef = ref(null);

    const state = reactive({
      confirmLoading: false,
      isEditName: false,
      newTaskName: null,
      formState: {
        transitShipmentId: null,
        warehouseId: null,
        taskName: null,
        taskDetails: [],
      },
      shipmentInfo: {
        name: null,
        seShipmentNo: null,
      },
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const columns = [
      {
        dataIndex: 'canScanedBoxNo',
        width: 200,
        title: () => vueI18n.t('warehouse.scannable_box_no'),
      },
      {
        dataIndex: 'boxNo',
        width: 200,
        title: () => vueI18n.t('warehouse.box_id'),
      },
      {
        width: 200,
        align: 'center',
        slots: {
          title: 'operation',
          customRender: 'operationCustomer',
        }
      },
    ];

    // 获取可选择的箱数量
    const getCanSelectCount = computed(() => {
      return state.tableData.tableList.filter(item => item.isOperability).length;
    });

    // 获取已选中的箱数量
    const getSelectedCount = computed(() => {
      return state.tableData.tableList.filter(item => item.selected).length;
    });

    const handleShowInput = () => {
      state.isEditName = true;
      nextTick(() => {
        refInputElemFocus(newTaskNameInputRef.value.input);
      });
    }

    const handleConfirmTaskName = () => {
      if (!state.newTaskName) {
        state.newTaskName = state.formState.taskName;
      } else {
        state.formState.taskName = state.newTaskName;
      }
      state.isEditName = false;
    }

    const handleSelectAll = () => {
      if (getSelectedCount.value !== getCanSelectCount.value) {
        state.tableData.tableList.forEach(item => {
          if (item.isOperability) {
            item.selected = true;
          }
        });
      } else {
        state.tableData.tableList.forEach(item => {
          item.selected = false;
        });
      }
    }

    const openShipmentModal = () => {
      shipmentListModalRef.value.open(state.formState.warehouseId, state.shipmentList);
    }

    const handleSetShipment = (record) => {
      state.formState.transitShipmentId = record.id;
      state.shipmentInfo.name = record.name;
      state.shipmentInfo.seShipmentNo = record.seShipmentNo;
      handleInitPage();
    }

    const getPageList = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.tableData.pageData,
        {
          shipmentId: state.formState.transitShipmentId
        }
      );
      getCreateLabelShipmentDetailPagedList(data)
        .then((res) => {
          state.tableData.loading = false;
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items.map(item => {
            item.selected = false;
            return item;
          });
        })
        .catch(() => {
          state.tableData.loading = false;
        });
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    };

    const handleInitPage = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageList();
    };

    const handleConfirm = () => {
      if (!state.formState.taskName) {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.label_task_name')]));
        return;
      }

      let taskDetails = state.tableData.tableList.filter(item => item.selected);
      if (taskDetails.length === 0) {
        message.error(vueI18n.t('warehouse.please_select_box'));
        return;
      }

      const data = Object.assign({}, state.formState);
      data.taskDetails = taskDetails;
      state.confirmLoading = true;
      createTransitsLabelChangeTask(data).then(({ result }) => {
        message.success(vueI18n.t('common.succeed'));
        handleNext(result);
      }).catch(() => {}).finally(() => {
        state.confirmLoading = false;
      });
    }

    const handleNext = (taskId) => {
      delVisitedRoute(router.currentRoute.value);
      router.push('/transit/labelchange/uploadtags/' + taskId);
    }

    watch(() => props.pageState, (value) => {
      if (value) {
        setFormStateValue(state.formState, value);
        state.newTaskName = value.taskName;
      }
    }, { deep: true, immediate: true });

    return {
      ...toRefs(state),
      shipmentListModalRef,
      newTaskNameInputRef,
      columns,
      getCanSelectCount,
      getSelectedCount,
      handleShowInput,
      handleConfirmTaskName,
      handleSelectAll,
      openShipmentModal,
      handleSetShipment,
      handlePage,
      handleConfirm,
    }
  }
})
</script>

<style scoped>

</style>