export const elemFocus = (elem, noKeyboard=true) => {
    if(elem && elem.focus){
        if(noKeyboard){
            elem.setAttribute && elem.setAttribute("readonly",true);
        }
        elem.focus();
        if(noKeyboard){
            setTimeout(function(){
                // elem.setAttribute && elem.setAttribute("readonly",false);
                elem.removeAttribute && elem.removeAttribute("readonly");
            },10);
        }
    }
}

const getDeepInputElem = (refInputElem) => {
    if(refInputElem){
        if(refInputElem["__v_isRef"] == true){
            return getDeepInputElem(refInputElem.value);
        }
        if(refInputElem.input){
            return getDeepInputElem(refInputElem.input);
        }
    }
    return refInputElem;
}

export const refInputElemFocus = (refInputElem, noKeyboard = true) =>{
    var elem = getDeepInputElem(refInputElem);
    elemFocus(elem, noKeyboard);
}
