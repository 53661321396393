<template>
  <a-steps :current="current" size="small" class="mb-3 pr-3">
    <a-step v-for="(item, index) in steps" :key="index" :title="item.title" />
  </a-steps>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue';
import { Steps } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  components: {
    ASteps: Steps,
    AStep: Steps.Step,
  },
  props: {
    current: {
      type: Number,
      default: 0
    }
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      steps: [
        {
          title: vueI18n.t('warehouse.first_step') + ': ' + vueI18n.t('warehouse.select_warehouse'),
        },
        {
          title: vueI18n.t('warehouse.second_step') + ': ' + vueI18n.t('menu.transit_label_change_create'),
        },
        {
          title: vueI18n.t('warehouse.third_step') + ': ' + vueI18n.t('warehouse.upload_tags_and_set_the_quantity'),
        },
        {
          title: vueI18n.t('warehouse.fourth_step') + ': ' + vueI18n.t('warehouse.confirm_and_pay'),
        },
      ],
    });

    return {
      ...toRefs(state),
    }
  }
})
</script>

<style scoped>

</style>