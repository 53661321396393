<template>
  <a-form
    ref="formRef"
    style="width: 450px"
    :model="formState"
    :rules="rules"
    :label-col="labelCol"
  >
    <a-form-item :label="$t('warehouse.target_warehouse')" name="warehouseId">
      <a-select
        v-model:value="formState.warehouseId"
        :loading="warehouseLoading"
        option-filter-prop="search-key"
        show-search
      >
        <a-select-option
          v-for="(item, index) in warehouseList"
          :key="index"
          :value="item.id"
          :title="item.warehouseNo + '(' + item.warehouseName + ')'"
          :search-key="item.warehouseNo + item.warehouseName"
        >{{ item.warehouseNo + '(' + item.warehouseName + ')' }}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item :label="$t('warehouse.label_task_name')" name="taskName">
      <a-input v-model:value="formState.taskName" :maxlength="128" allow-clear />
    </a-form-item>
    <a-form-item>
      <a-button style="margin-left: 150px;" type="primary" @click="handleNext">{{ $t('common.next_step') }}</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import { defineComponent, reactive, onMounted, toRefs, ref } from 'vue';
import { Button, Form, Input, Select } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";
import { getInvWarehouseList } from '@/api/modules/transit/labelChange.js';

export default defineComponent({
  name: 'stepZero',
  emits: ['next'],
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const formRef = ref(null);

    const state = reactive({
      warehouseLoading: false,
      warehouseList: [],
      formState: {
        warehouseId: null,
        taskName: null,
      }
    });

    const rules = {
      warehouseId: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.target_warehouse')]),
      },
      taskName: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.label_task_name')]),
      },
    }

    const getWarehouseList = () => {
      state.warehouseLoading = true;
      getInvWarehouseList().then(({ result }) => {
        if (Array.isArray(result)) {
          state.warehouseList = result;
        } else {
          state.warehouseList = [];
        }
      }).catch(() => {}).finally(() => {
        state.warehouseLoading = false;
      });
    }

    const handleNext = async () => {
      try {
        await formRef.value.validate();
        let warehouse = state.warehouseList.find(item => item.id === state.formState.warehouseId);
        const pageData = Object.assign({}, state.formState, { warehouse });
        emit('next', pageData);
      } catch (error) {
        
      }
    }

    onMounted(getWarehouseList);

    return {
      ...toRefs(state),
      labelCol: { style: { width: '150px', } },
      formRef,
      rules,
      handleNext,
    }
  }
})
</script>

<style scoped></style>